:root {
  --font-size-72: 72pt;
  --font-size-24: 1.5rem;
  --font-size-16: 1rem;
  --font-size-12: 0.75rem;
  --font-size-10: 0.625rem;

  --near-white: #f5f5f5;
  --near-black: #262626;

  --dark-gray: #3d3d3d;
  --medium-gray: #555555;
  --gray: #6a6a6a;
  --light-gray: #979797;
  --lightest-gray: #b3b3b3;
  --lighter-gray: #c7c7c7;
  --washed-gray: #dedede;
  --faint-gray: #ededed;
  --fainter-gray: #fafafa;

  // Spacing
  --spacing-2: 2px;
  --spacing-4: 4px;
  --spacing-8: 8px;
  --spacing-12: 12px;
  --spacing-16: 16px;

  // Shadows
  --large-shadow: 0 8px 30px rgba(14, 21, 47, 0.3);
  --super-shadow: 0 45px 100px rgba(14, 21, 47, 0.2),
    0 16px 40px rgba(14, 21, 47, 0.1);
}
