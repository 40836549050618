.gallery {
  overflow-x: scroll;
  background-color: var(--gray);
  height: 55%;
  padding: 0 25px;
  max-height: 500px;
  display: flex;
  flex-direction: row;

  &__image {
    padding: 45px 45px 45px 0;
    height: calc(100% - 90px);
    object-fit: contain;

    &-container {
      padding: 45px 0;
    }

    &-caption {
      color: var(--near-white);
    }
  }
}
