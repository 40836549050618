@import "./common.scss";

* {
  cursor: none;
}

body {
  margin: 0;
  font-family: "Source Sans Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

h1,
h2,
h3,
p {
  margin: 0;
  padding: 0 10px;
}

h1,
h1 {
  padding-top: 5px;
}

p {
  max-width: 750px;
}

#root {
  height: 100vh;
  width: 100vw;
}
