.scrollbar {
  position: absolute;
  right: 5px;
  top: 5px;
  bottom: 5px;
  width: 8px;
  border-radius: 5px;
  background-color: var(--light-gray);

  &__bar {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    border-radius: 5px;
    background-color: var(--lighter-gray);
  }
}
