.project-icon {
  will-change: transform;

  &,
  &__container,
  &__image {
    width: var(--project-size);
    height: var(--project-size);
  }

  &,
  &__container {
    position: absolute;
    transform-style: flat;
  }

  user-select: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  touch-action: none;
  overflow: hidden;
  border-radius: var(--icon-border-radius);
  background: var(--near-white);

  transition: box-shadow 0.5s;
  box-shadow: var(--large-shadow);
  &__shadow {
    box-shadow: var(--super-shadow);
  }

  &__content {
    position: absolute;
    top: 0;
  }
}
