.cursor {
  &__container {
    z-index: 100;
    pointer-events: none;
    top: 0;
    left: 0;

    &,
    .cursor__content {
      position: absolute;
    }

    .cursor__content {
      transform: translate(-50%, -50%);
      background: #000;
      opacity: 0.3;
      border-radius: 0.5em;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
    }
  }
}
