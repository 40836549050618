.window {
  $border-radius: 20px;
  height: 100%;
  width: 100%;
  border-radius: $border-radius;
  transform-origin: top left;

  &__container {
    pointer-events: all;
    user-select: none;
    position: absolute;
  }

  &__close {
    position: absolute;
    top: 10px;
    left: 10px;
    background: var(--light-gray);
    color: var(--near-white);
    padding: 5px;
    border-radius: 15px;
    width: 20px;
    height: 20px;
  }

  &__header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px;
    background: rgba(0, 0, 0, 0.5);
    color: var(--near-white);
  }

  &__content {
    box-shadow: var(--large-shadow);
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: $border-radius;
    pointer-events: all;
    background: var(--near-white);
    overflow-y: scroll;
  }

  &__icon {
    transform-origin: top left;
    pointer-events: none;
    position: absolute;
    top: 0;
    overflow: hidden;
    border-radius: var(--icon-border-radius);
    width: var(--project-size);
    height: var(--project-size);
    background-color: var(--near-white);
    box-shadow: var(--large-shadow);
  }
}
