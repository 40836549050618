.project {
  &__container {
    overflow-x: hidden;
    overflow-y: scroll;
    padding-bottom: var(--spacing-16);
    width: 100%;

    h1 {
      font-size: var(--font-size-72);
    }

    h2 {
      font-size: var(--font-size-24);
    }

    p {
      margin-top: var(--spacing-12);
      font-size: var(--font-size-16);
    }
  }

  &__links {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: var(--spacing-16);
  }

  &__link {
    text-decoration: underline;
    color: var(--project-background);
  }

  &__header,
  &__body {
    padding: 0 5px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__icon-content {
    padding-bottom: var(--spacing-12);

    h2 {
      font-size: var(--font-size-24);
    }

    h3 {
      font-size: var(--font-size-12);
    }

    p {
      margin-top: var(--spacing-4);
      font-size: var(--font-size-10);
    }
  }
}

.resume {
  pointer-events: none;
  width: 100%;
  height: 100%;
}
