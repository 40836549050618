@import "./variables.scss";

.fs-title {
  font-size: var(--font-size-24);
}
.fs-large {
  font-size: var(--font-size-18);
}
.fs-medium {
  font-size: var(--font-size-16);
}
.fs-small {
  font-size: var(--font-size-14);
}
.fs-xsmall {
  font-size: 13px;
}

.pa0 {
  padding: 0;
}
.pa2 {
  padding: var(--spacing-2);
}
.pa4 {
  padding: var(--spacing-4);
}
.pa8 {
  padding: var(--spacing-8);
}
.pa12 {
  padding: var(--spacing-12);
}
.pa16 {
  padding: var(--spacing-16);
}
.pa28 {
  padding: var(--spacing-28);
}
.pa32 {
  padding: var(--spacing-32);
}
.pa36 {
  padding: var(--spacing-36);
}

.absolute-fill {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
}
.pointer-none {
  pointer-events: none;
}
.h-100 {
  height: 100%;
}
.w-100 {
  width: 100%;
}
